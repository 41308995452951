<template>
  <div>
    <div class="classDetail-tz">
      <span class="present">当前位置：</span>
      <span>班级管理</span>
      <span class="el-icon-arrow-right"></span>
      <span>班级审核</span>
    </div>
    <div class="classDetail-center clear">
      <div class="class_detail-list">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="待审核" name="0">
            <div class="class-list">
              <div class="to-lead-item ">
                <el-checkbox-group
                    v-model="checkedCities"
                    @change="handleCheckedCitiesChange">
                  <el-checkbox
                      style="position: relative;background: #F8F8F8;margin-top: 20px;
                width: 200px;height: 70px;line-height: 70px;padding-left: 10px"
                      v-for="(city,index) in cities"
                      :label="city.id"
                      :key="index">
                    <div class="checkbox-items dis-flex">
                      <div class="checkbox-item-img">
                        <img :src="city.avatar_url" alt="">
                      </div>
                      <p>{{ city.name }}</p>
                    </div>
                  </el-checkbox>
                </el-checkbox-group>
                <!--            全选-->
                <div class="dis-flex margin-auto-20">
                  <el-checkbox
                      style="padding-left: 10px;height: 32px;
                   line-height: 32px;"
                      :indeterminate="isIndeterminate"
                      v-model="checkAll"
                      @change="handleCheckAllChange">全选
                  </el-checkbox>
                  <div class="del cursor-pointer" @click="refuse">拒绝</div>
                  <div class="pass cursor-pointer" @click="pass">通过</div>
                </div>
              </div>

            </div>
          </el-tab-pane>
          <el-tab-pane label="审核通过" name="1">
            <ul class="pass-center dis-flex">
              <li class="pass-li dis-flex" v-for="(ps,index) in ok_apply" :key="index">
                <div class="pass-li-img">
                  <img :src="ps.avatar_url" alt="">
                </div>
                <h3>{{ps.name}}</h3>
              </li>
            </ul>
          </el-tab-pane>
          <el-tab-pane label="审核拒绝" name="2">
            <ul class="pass-center dis-flex">
              <li class="pass-li dis-flex" v-for="(jen,index) in refuse_apply" :key="index">
                <div class="pass-li-img">
                  <img :src="jen.avatar_url" alt="">
                </div>
                <h3>{{jen.name}}</h3>
              </li>
            </ul>
          </el-tab-pane>
        </el-tabs>

      </div>
    </div>
  </div>
</template>

<script>
import api from '@/service/api/api'

export default {
  name: "classcheck",
  data() {
    return {
      activeName: '0',
      checkAll: false,
      isIndeterminate: false,
      checkedCities: [],
      cities: [],
      // 全选数组
      checkedGameId: [],
      // 审核通过
      ok_apply:[],
      // 啥拒绝
      refuse_apply:[],
    }
  },
  created() {
    this.init()
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab.index, event);
      this.activeName = tab.index
      // this.init()
    },
    // 待审核初始化
    init() {
      let params = {
        class_id: this.$route.query.id,
      }
      console.log(params)
      api.checkclass(params).then(res => {
        if (res.errcode === 0) {
          this.cities = res.data.not_apply
          console.log(res.data.ok_apply)
          this.ok_apply = res.data.ok_apply
          this.refuse_apply = res.data.refuse_apply
          for (let i = 0; i < this.cities.length; i++) {
            this.checkedGameId.push(this.cities[i].id);
          }
        } else {
          this.$message({
            message: res.errmsg,
            duration: 2000,
            type: "warning"
          });
        }
      })
    },
    // 待审核---------------------------------------------------------
    handleCheckAllChange(val) {
      console.log(this.checkedGameId)
      this.checkedCities = val ? this.checkedGameId : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      console.log(value, this.checkedCities)
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;

    },
    // 通过
    pass() {
         let params = {
           classmember_id:this.checkedCities.join(','),
           status:1
         }
      api.passcheckclass(params).then(res=>{
        if (res.errcode === 0) {
          this.init()
          this.$message({
            message: res.errmsg,
            duration: 2000,
            type: "warning"
          });
        } else {
          this.$message({
            message: res.errmsg,
            duration: 2000,
            type: "warning"
          });
        }
      })
    },
    // 拒绝
    refuse() {
      let params = {
        classmember_id:this.checkedCities.join(','),
        status:2
      }
      api.passcheckclass(params).then(res=>{
        if (res.errcode === 0) {
          this.init()
          this.$message({
            message: res.errmsg,
            duration: 2000,
            type: "warning"
          });
        } else {
          this.$message({
            message: res.errmsg,
            duration: 2000,
            type: "warning"
          });
        }
      })
    },
    // 审核通过---------------------------------------------------------
    // 已拒绝---------------------------------------------------------
  }
}
</script>

<style scoped>
.classDetail-center {
  width: 1200px;
  margin: 0 auto;
  min-height: 200px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 21px 3px rgba(4, 0, 0, 0.05);
  border-radius: 8px;
}

.classDetail-tz {
  width: 1200px;

  height: 44px;
  line-height: 44px;
  padding-left: 10px;
  margin: 20px auto;
  color: #FF8E42;
  font-size: 16px;
}

.present {
  color: #999999;
}

.class_detail-list {
  width: 98%;
  min-height: 200px;
  margin: 0 auto;
}


.class-list {
  width: 100%;
  min-height: 200px;
}

.checkbox-items {
  width: 200px;
  height: 70px;
  /*margin-top: 20px;*/
  position: absolute;
  left: 50px;
  top: 0;
}

.checkbox-item-img {
  width: 44px;
  height: 44px;
  margin-top: 13px;
  border-radius: 4px;
}

.checkbox-item-img > img {
  width: 100%;
  height: 100%;
  border-radius: 4px;

}

.checkbox-items p {
  width: 100px;
  height: 70px;
  line-height: 70px;
  padding-left: 10px;
}

.del {
  width: 80px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #FF8E42;
  border: 1px solid #FF8E42;
  border-radius: 8px;
  margin-left: 10px;
  margin-top: 2px;
}

.pass {
  width: 80px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #FFFFFF;
  background: #FF8E42;
  border-radius: 8px;
  margin-left: 10px;
  margin-top: 2px;
}

/*审核通过*/
.pass-center {
  width: 100%;
  min-height: 200px;
  height: 100px;
  flex-wrap: wrap;
  /*justify-content: center;*/
}

.pass-li {
  width: 200px;
  height: 70px;
  margin: 20px 20px 0 0;
  background-color: #F8F8F8;
}

.pass-li-img {
  width: 44px;
  height: 44px;
  margin-top: 13px;
  margin-left: 20px;
  border-radius: 4px;
}


.pass-li-img > img {
  width: 100%;
  height: 100%;
  border-radius: 4px;

}

.pass-li h3 {
  width: 100px;
  height: 70px;
  line-height: 70px;
  padding-left: 10px;
}

/*已拒绝*/
</style>